// highlights.js
document.addEventListener('DOMContentLoaded', function () {
    function highlightInputField(inputElement) {
        inputElement.focus();
        inputElement.classList.add('border', 'border-blue-500', 'shadow-outline');

        let instructionElement = document.getElementById('instruction-text');
        if (!instructionElement) {
            instructionElement = document.createElement('div');
            instructionElement.id = 'instruction-text';
            instructionElement.className = 'text-blue-500 text-sm mt-2';
            instructionElement.textContent = 'Klaar voor de start? Voer jouw adres in en klik op "Volgende"';
            inputElement.parentNode.insertBefore(instructionElement, inputElement.nextSibling);
        }

        // Scroll naar het element op mobiel
        inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });

        inputElement.addEventListener('input', () => {
            inputElement.classList.remove('border-blue-500', 'shadow-outline');
            if (instructionElement) {
                instructionElement.remove();
            }
        });
    }

    function handleClick(event) {
        event.preventDefault();
        const adresInput = document.getElementById('adres');
        highlightInputField(adresInput);
    }

    const startButton = document.querySelector('.start-schatting');
    if (startButton) {
        startButton.addEventListener('click', handleClick);
        startButton.addEventListener('touchstart', handleClick); // For mobile devices
    }

    // Check if URL contains the highlight parameter
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('highlight')) {
        const adresInput = document.getElementById('adres');
        if (adresInput) {
            highlightInputField(adresInput);
        }
    }
});
