document.addEventListener('DOMContentLoaded', function () {
    const savedAddress = sessionStorage.getItem('selectedAddress');
    const savedLatitude = sessionStorage.getItem('latitude');
    const savedLongitude = sessionStorage.getItem('longitude');
    const adresInput = document.getElementById('adres');

    if (savedAddress) {
        adresInput.value = savedAddress;
        document.getElementById('latitude').value = savedLatitude;
        document.getElementById('longitude').value = savedLongitude;
    }

    function updateSurfacePlotVisibility(resetSurfacePlotValue = true) {
        var propertyType = document.getElementById('propertyType').value;
        var surfacePlotInput = document.getElementById('surfacePlot');
        var surfacePlotContainer = document.getElementById('surfacePlotContainer');

        if (propertyType === 'HOUSE') {
            surfacePlotContainer.style.display = 'block';
            surfacePlotInput.required = true;
            if (resetSurfacePlotValue) {
                surfacePlotInput.value = '';
            }
        } else {
            surfacePlotContainer.style.display = 'none';
            surfacePlotInput.required = false;
            surfacePlotInput.value = '100';
        }
    }

    updateSurfacePlotVisibility();

    const fields = [
        { id: 'buildableArea', message: 'Voer de bewoonbare oppervlakte in.' },
        { id: 'bedroomCountNumber', message: 'Voer het aantal slaapkamers in.' },
        { id: 'energyConsumptionPerSqm', message: 'Voer de energiescore in.' },
        { id: 'surfacePlot', message: 'Voer de oppervlakte van het perceel in.' },
        { id: 'propertyType', message: 'Selecteer het type eigendom.' },
        { id: 'buildingCondition', message: 'Selecteer de staat van het gebouw.' },
        { id: 'epcLabel', message: 'Selecteer het EPC label.' }
    ];

    fields.forEach(function (field) {
        var element = document.getElementById(field.id);
        if (element) {
            element.addEventListener('input', function () {
                clearError(element);
                element.setCustomValidity('');
            });

            element.addEventListener('invalid', function () {
                event.preventDefault();
                showError(element, field.message);
            });
        }
    });
});
