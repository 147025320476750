import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

function updateMap(latitude, longitude) {
    map.flyTo({
        center: [longitude, latitude],
        essential: true, // Dit zorgt voor een vloeiende overgang
        zoom: 14
    });

    // Update verborgen velden met latitude en longitude waarden
    document.getElementById('latitude').value = latitude;
    document.getElementById('longitude').value = longitude;

    // Als je een marker op de kaart hebt, update deze dan ook
    if (window.propertyMarker) {
        window.propertyMarker.setLngLat([longitude, latitude]);
    } else {
        window.propertyMarker = new mapboxgl.Marker()
            .setLngLat([longitude, latitude])
            .addTo(map);
    }
}


// Function to perform the GraphQL query
async function searchAddress(query) {
    const response = await fetch('https://woon-info-graphql-hoshtgewlq-ew.a.run.app/graphql', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify({
            query: `query SearchAddress {
            searchAddress(input: "${query}") {
                address
                latitude
                longitude
            }
        }`
        })
    });

    const data = await response.json();
    return data.data.searchAddress.map(item => item);
}

let currentSuggestions = [];

// Function to show suggestions
function showSuggestions(suggestions) {

    currentSuggestions = suggestions; // Sla de huidige suggesties op

    activeSuggestionIndex = -1; // Reset de actieve suggestie index
    const suggestionsContainer = document.getElementById('suggestions');
    suggestionsContainer.innerHTML = '';

    suggestions.forEach(item => {
        //console.log(item)   
        const div = document.createElement('div');
        div.className = 'p-2 hover:bg-gray-200 cursor-pointer';
        div.textContent = item.address;
        div.onclick = function () {
            document.getElementById('adres').value = item.address;
            updateMap(item.latitude, item.longitude); // Update de kaart
            suggestionsContainer.innerHTML = '';

            // Sla de geselecteerde suggestie op
            sessionStorage.setItem('selectedAddress', item.address);
            sessionStorage.setItem('latitude', item.latitude);
            sessionStorage.setItem('longitude', item.longitude);

            // Scroll het scherm naar de sectie waarin de kaart zich bevindt op mobiele apparaten
            if (window.innerWidth < 768) {
                document.getElementById('map').scrollIntoView({
                    behavior: 'smooth', // Vloeiende scroll animatie
                    block: 'start' // Scroll naar het begin van de sectie
                });
            }
        };
        suggestionsContainer.appendChild(div);
    });
}


// Event listener for the address input
document.getElementById('adres').addEventListener('input', async (e) => {
    const query = e.target.value;
    if (query.length > 2) {
        const suggestions = await searchAddress(query);
        showSuggestions(suggestions);
    } else {
        document.getElementById('suggestions').innerHTML = '';
    }
});

let activeSuggestionIndex = -1; // Houdt bij welke suggestie momenteel actief is


document.getElementById('adres').addEventListener('keydown', async (e) => {
    const suggestionsContainer = document.getElementById('suggestions');
    const suggestions = suggestionsContainer.querySelectorAll('div');

    if (e.key === 'ArrowDown') {
        activeSuggestionIndex = (activeSuggestionIndex + 1) % suggestions.length;
        e.preventDefault(); // Voorkom dat de cursor beweegt
        console.log('ArrowDown')
    } else if (e.key === 'ArrowUp') {
        if (activeSuggestionIndex <= 0) {
            activeSuggestionIndex = suggestions.length - 1;
        } else {
            activeSuggestionIndex--;
        }
        e.preventDefault(); // Voorkom dat de cursor beweegt en scrollen
    } else if (e.key === 'Enter') {
        if (activeSuggestionIndex >= 0 && suggestions.length > 0) {
            // Er is een actieve suggestie geselecteerd met de pijltjestoetsen
            suggestions[activeSuggestionIndex].click();
            e.preventDefault(); // Voorkom form submission als een suggestie actief is
        }
    }

    // Markeer de actieve suggestie
    highlightActiveSuggestion(suggestions, activeSuggestionIndex);
});

function highlightActiveSuggestion(suggestions, index) {
    suggestions.forEach((suggestion, i) => {
        if (i === index) {
            suggestion.classList.add('bg-blue-100'); // Highlight stijl
            suggestion.scrollIntoView({ block: 'nearest' });
        } else {
            suggestion.classList.remove('bg-blue-100');
        }
    });
}





document.getElementById('increment').addEventListener('click', function () {
    var input = document.getElementById('bedroomCountNumber');
    input.value = parseInt(input.value, 10) + 1;
});

document.getElementById('decrement').addEventListener('click', function () {
    var input = document.getElementById('bedroomCountNumber');
    var value = parseInt(input.value, 10);
    if (value > 0) {
        input.value = value - 1;
    }
});

const epcToEnergyMapping = {
    'A+': -10,    // Aanname: A+ heeft een specifieke lage waarde die je kunt definiëren
    'A': 50,
    'B': 150,
    'C': 250,
    'D': 350,
    'E': 450,
    'F': 550,   // Pas dit aan op basis van de werkelijke range voor F
};

document.getElementById('epcLabel').addEventListener('change', function () {
    const selectedEpcLabel = this.value;
    const energyInput = document.getElementById('energyConsumptionPerSqm');
    energyInput.value = epcToEnergyMapping[selectedEpcLabel] || ''; // Stel in op de middelste waarde of leeg als niet gevonden
});


// epc waarden
document.getElementById('energyConsumptionPerSqm').addEventListener('input', function () {
    var energyConsumption = this.value;
    var epcLabel = document.getElementById('epcLabel');

    if (energyConsumption < 0) {
        epcLabel.value = 'A+';
    } else if (energyConsumption <= 100) {
        epcLabel.value = 'A';
    } else if (energyConsumption <= 200) {
        epcLabel.value = 'B';
    } else if (energyConsumption <= 300) {
        epcLabel.value = 'C';
    } else if (energyConsumption <= 400) {
        epcLabel.value = 'D';
    } else if (energyConsumption <= 500) {
        epcLabel.value = 'E';
    } else {
        epcLabel.value = 'F';
    }
});
document.getElementById('propertyType').addEventListener('change', updateSurfacePlotVisibility);

function updateSurfacePlotVisibility(resetSurfacePlotValue = true) {
    var propertyType = document.getElementById('propertyType').value;
    var surfacePlotInput = document.getElementById('surfacePlot');
    var surfacePlotContainer = document.getElementById('surfacePlotContainer');

    if (propertyType === 'HOUSE') {
        surfacePlotContainer.style.display = 'block';
        surfacePlotInput.required = true;
        if (resetSurfacePlotValue) {
            surfacePlotInput.value = '';
        }
    } else {
        surfacePlotContainer.style.display = 'none';
        surfacePlotInput.required = false;
        surfacePlotInput.value = '100'; // Stel een standaardwaarde in voor niet-huizen, indien nodig

    }
}

// Vervang 'DOMContentLoaded' met 'pageshow' om zeker te zijn dat de functie wordt uitgevoerd na terugkeer
window.addEventListener('pageshow', function () {
    updateSurfacePlotVisibility(false); // Voer de functie uit zonder de standaardwaarde te resetten
});

// JavaScript om scroll aanpassingen te voorkomen
document.getElementById('surfacePlot').addEventListener('wheel', function (event) {
    event.preventDefault();
});

document.getElementById('buildableArea').addEventListener('wheel', function (event) {
    event.preventDefault();
});

document.getElementById('energyConsumptionPerSqm').addEventListener('wheel', function (event) {
    event.preventDefault();
});

document.getElementById('next-button').addEventListener('click', function (event) {
    event.preventDefault(); // Voorkomt dat de pagina ververst wordt

    var form = document.querySelector('form');
    const adresInput = document.getElementById('adres');
    const inputAddress = adresInput.value;
    const savedAddress = sessionStorage.getItem('selectedAddress');
    const isAddressValid = currentSuggestions.some(item => item.address === inputAddress);

    // Check of het adres overeenkomt met het opgeslagen adres in sessionStorage
    if (inputAddress === savedAddress) {
        // Behandel dit als een geldig geselecteerd adres en ga verder
        document.getElementById('adres-zoekbox').style.display = 'none';
        document.getElementById('vervolg-formulier').style.display = 'block';
    } else {
        if (!isAddressValid) {
            // Stel een aangepaste foutmelding in en trigger de validatie
            showError(adresInput, 'Selecteer een geldig adres uit de suggesties.'); // Toon de foutmelding met Tailwind
        }

        if (isAddressValid) {
            // Als alles geldig is, ga verder met de logica
            document.getElementById('adres-zoekbox').style.display = 'none';
            document.getElementById('vervolg-formulier').style.display = 'block';

        } else {
            // Forceer de browser om de standaard validatie UI te tonen
            showError(adresInput, 'Selecteer een geldig adres uit de suggesties.'); // Toon de foutmelding met Tailwind
        }
    }
});

function showError(inputElement, message) {
    const id = inputElement.id;
    let messageElement = document.getElementById(id + '-error');
    if (!messageElement) {
        messageElement = document.createElement('div');
        messageElement.id = id + '-error';
        messageElement.className = 'text-red-500 text-sm mt-2';
        inputElement.parentNode.insertBefore(messageElement, inputElement.nextSibling);
    }
    messageElement.textContent = message;
    inputElement.classList.add('border', 'border-red-500');
}

function clearError(inputElement) {
    inputElement.classList.remove('border', 'border-red-500'); // Verwijder fout styling
    let errorElement = document.getElementById(inputElement.id + '-error');
    if (errorElement) {
        errorElement.remove(); // Verwijder de foutmelding
    }
}

// Zorg ervoor dat de foutmelding verdwijnt wanneer de gebruiker begint te typen in het veld
document.getElementById('adres').addEventListener('input', function () {
    clearError(this); // Verwijder de foutmelding en styling voor dit veld
});



document.getElementById('back-button').addEventListener('click', function (event) {
    event.preventDefault(); // Voorkomt dat de pagina ververst wordt

    // Verberg het vervolgformulier
    document.getElementById('vervolg-formulier').style.display = 'none';

    // Toon de adres-zoekbox
    document.getElementById('adres-zoekbox').style.display = 'block';
});


